import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        const anySwiper = document.querySelector('.swiper:not(.product-images)');

        if (!anySwiper) return;

        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN BANNER
            const mainBanner = new Swiper('.main-banner', {
                modules: [Pagination, Autoplay],
                preloadImages: false,
                autoplay: {
                    delay: 7000
                },
                lazy: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                on: {
                    beforeTransitionStart: function (instance) {
                        const currentSlide = instance.slides[instance.activeIndex];
                        const slideImg = currentSlide.querySelector('img');
                        
                        if (slideImg.hasAttribute('src')) return;

                        currentSlide.classList.add('loaded');
                        slideImg.setAttribute('src', slideImg.getAttribute('data-src'));
                    }
                }
            });

            //MAIN PAGE PRODUCTS
            const breakpoints = {
                1: {},
                375: {},
                601: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.slider-with-products.slider1 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button1',
                    prevEl: '.swiper-button-prev.slider-button1'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: Number(cs.getPropertyValue('--prodSwiperCols')),
                spaceBetween: Number(cs.getPropertyValue('--prodGapHor').replace('px', '')),
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = Number(cs.getPropertyValue('--prodSwiperCols'));
                slider1.params.spaceBetween = Number(cs.getPropertyValue('--prodGapHor').replace('px', ''));
                slider1.update();
            });

            //PRODUCERS
            const producersBanner = new Swiper('.producers-banner .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.producer-button',
                    prevEl: '.swiper-button-prev.producer-button'
                },
                loop: false,
                spaceBetween: 32,
                breakpoints: {
                    1: {
                        slidesPerView: 3,
                        spaceBetween: 16
                    },
                    601: {
                        slidesPerView: 4
                    },
                    1100: {
                        slidesPerView: 5
                    },
                    1280: {
                        slidesPerView: 6
                    },
                    1681: {
                        slidesPerView: 7
                    }
                }
            });
        });
    }, false);
}